import { CancellablePromise } from '../../helpers/cancellablePromise';
import middlewarePromise from '../../helpers/middlewarePromise';

export function setPromiseMiddleware<T extends { [smth in K as K]?: CancellablePromise<void> }, K extends keyof T>(obj: T, key: K) {
    const oldPromise: CancellablePromise<void> = obj[key] as any;
    oldPromise?.reject();
  
    // @ts-ignore
    const deferred = obj[key] = deferredPromise<void>();
    deferred.catch(() => { }).finally(() => {
      if ((obj[key] as any) === deferred) {
        delete obj[key];
      }
    });
  
    const middleware = middlewarePromise(() => (obj[key] as any) === deferred);
    return { deferred, middleware };
  }