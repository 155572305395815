import { S3Client, PutObjectCommand } from '@aws-sdk/client-s3'
import SnowflakeId from "snowflake-id";
import SecretStore, {AwsConfig} from './SecretStore';

const snowflake = new SnowflakeId({
    mid: 42,
    offset: (2023 - 1970) * 31536000 * 1000,
});

export const CDN_PREFIX = "https://cdn.yay.chat/"

export const CND_INDEX_DIR: string = 'operations/'

export const CND_TOS_DIR: string = 'tolink-tweb'

export default class AwsUploader {

    private static ins: AwsUploader = null

    public static getIns(): AwsUploader{
        if(AwsUploader.ins == null || AwsUploader.ins.s3 == null){
            AwsUploader.ins = new AwsUploader()
        }
        return AwsUploader.ins;
    }

    private bucket: string = 'yaychat-static-resource'

    private s3: S3Client = null

    private constructor() {
        const awsConfig = SecretStore.getIns().get<AwsConfig>(SecretStore.KEY_AWS)
        if(awsConfig && awsConfig != null){
            this.s3 = new S3Client({
                region: awsConfig.region,
                credentials: {
                  accessKeyId: awsConfig.key,
                  secretAccessKey: awsConfig.secret
                }
              })
        }
    };

    async upload(file: File, key?: string,
        contentType?: string,
        bucket: string = this.bucket,
        indexDir: string = CND_INDEX_DIR,
        tosDir: string = CND_TOS_DIR) {
        
        if(this.s3 == null){
            return null
        }

        if (!key) {
            let nameSuffixs = file.name.split(".")
            let nameSufix = nameSuffixs[nameSuffixs.length - 1]
            key = `${indexDir}${tosDir}/${snowflake.generate()}_${Date.now()}.${nameSufix}`
        }

        let params = {
            Bucket: bucket, // 存储桶名称
            Key: key, // 文件名，重名会覆盖
            Body: file,
            ContentType: contentType
        }
        // 创建一个 PutObjectCommand 实例
        const putObjectCommand = new PutObjectCommand({
            Bucket: bucket,
            Key: key,
            Body: file,
            ContentType: contentType
        })
        console.log(`params:${params}`)
        let uploadResult = await this.s3.send(putObjectCommand)
        console.log(uploadResult);
        return `${CDN_PREFIX}${key}`;
    }

}

