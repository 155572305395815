import rootScope from "../../lib/rootScope";
import readOnlineFile from "../utils/readFile";
import appImManager from '../../lib/appManagers/appImManager';
import { getTargetLang, translateSend } from "./translate_util";

export async function sendText(peerId: number, text: string) {
  let sendText:string = await translateSend(text, getTargetLang(peerId));
  
  if (sendText == undefined || sendText == null || sendText.trim().length == 0) {
    console.log(`Tolink translate is ${sendText}, reset`);
    sendText = text;
  }
  console.log(`Tolink sendMsg with peerId: ${peerId}, text: ${text}, translate: ${sendText}`);
  rootScope.managers.appMessagesManager
    .sendText(peerId, sendText, {
      peerId,
      noWebPage: true,
      webPage: null,
      webPageOptions: null,
    });
}

export function sendImage(peerId: number, url: string) {
  readOnlineFile(url).then((file) => {
    rootScope.managers.appMessagesManager.sendFile(peerId, {
      peerId,
      file,
      objectURL: url,
      isMedia: true,
      clearDraft: true,
    });
  });
}

function base64ToBlob(base64) {
  // 提取内容类型和解码 base64
  const parts = base64.split(';base64,');
  const contentType = parts[0].split(':')[1];
  const decodedData = atob(parts[1]);

  // 转换为二进制数据
  const byteArrays = [];

  for (let offset = 0; offset < decodedData.length; offset += 512) {
    const slice = decodedData.slice(offset, offset + 512);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  // 创建 Blob 对象
  return new Blob(byteArrays, { type: contentType });
}
export function sendImageByBase64(peerId: number, base64: string) {
  let file = base64ToBlob(base64);
  console.log('**sendImageByBase64 file :>> ', file);
  rootScope.managers.appMessagesManager.sendFile(peerId, {
    peerId,
    file,
    objectURL: base64,
    isMedia: true,
    clearDraft: true,
  });
  // readOnlineFile(url).then((file) => {
  //   let file = base64ToBlob(base64, 'image/png');
  //   rootScope.managers.appMessagesManager.sendFile(peerId, {
  //     peerId,
  //     file,
  //     objectURL: base64,
  //     isMedia: true,
  //     clearDraft: true,
  //   });
  // });
}

export function sendMsg(type: string, content: string) {
  const peerId = appImManager.chat.peerId;
  console.log(`Tolink sendMsg with type:${type}, peerId: ${peerId}`);
  if (type == "1") {
    sendText(peerId, content);
  } else if (type == "2") {
    sendImage(peerId, content);
  }
}