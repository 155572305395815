export type BroadcasterCallback = (event: TolinkEvent)=>void

const CHANNEL = 'tolink::event'

export class TolinkBroadcaster{

    private broadcastChannel: BroadcastChannel

    private callbacks: Array<BroadcasterCallback>

    private static ins: TolinkBroadcaster = null

    private constructor(){
        this.callbacks = []
        this.broadcastChannel = new BroadcastChannel(CHANNEL)
        const that = this;
        this.broadcastChannel.onmessage = (e) => {
            for(const callback of that.callbacks){
                callback(e.data)
            }
        }
    }

    public static get(): TolinkBroadcaster{
        if(this.ins == null){
            this.ins = new TolinkBroadcaster()
        }
        return this.ins
    }

    addCallback(callback: BroadcasterCallback){
        this.callbacks.push(callback)
    }

    removeCallback(callback: BroadcasterCallback){
        const findIndex = this.callbacks.findIndex((v)=>v==callback)
        if(findIndex >= 0){
            this.callbacks.splice(findIndex,1)
        }
    }

    emit(event: TolinkEvent){
        this.broadcastChannel.postMessage(event)
    }

}

export interface TolinkEvent{
    event: string
    data?: any
}