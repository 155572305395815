
import { LRUCache } from "lru-cache";

class CacheUtil {

    private memoryCache: LRUCache<string, any>;
    private dbName: string;

    constructor(capacity: number, dbName: string, version = 1) {
        this.memoryCache = new LRUCache({ max: capacity });
        this.dbName = dbName;
        this.initDatabase(version);
    }

    private initDatabase(version: number): void {
        const request = indexedDB.open(this.dbName, version);





        request.onerror = (event) => {
            console.error('Database error: ', request.error);
        };
        request.onsuccess = (event) => {
            console.log('**Tolink indexDB onsuccess');
            const db = request.result;
            Object.values(CacheTypes).forEach(value => {
                if (!db.objectStoreNames.contains(value)) {
                    console.log(`**Tolink indexDB onupgradeneeded, create object store of ${value}`);
                    db.createObjectStore(value);
                } else {
                    console.log(`**Tolink indexDB onupgradeneeded, object ${value} already create, do nothing`);
                }
            });

        }
        request.onupgradeneeded = (event) => {
            const db = request.result;

            Object.values(CacheTypes).forEach(value => {
                if (!db.objectStoreNames.contains(value)) {
                    console.log(`Tolink indexDB onupgradeneeded, create object store of ${value}`);
                    db.createObjectStore(value);
                } else {
                    console.log(`Tolink indexDB onupgradeneeded, object ${value} already create, do nothing`);
                }
            });
        };
    }

    async get(key: string, type: string): Promise<any> {
        const valueFromMemory = this.memoryCache.get(key);

        if (valueFromMemory !== undefined) {
            return valueFromMemory;
        }

        const valueFromDB = await this.getFromDB(key, type);
        if (valueFromDB !== undefined) {
            this.memoryCache.set(key, valueFromDB);
        }

        return valueFromDB;
    }

    async put(key: string, type: string, value: any): Promise<void> {
        this.memoryCache.set(key, value);
        await this.putInDB(key, type, value);
    }

    private async getFromDB(key: string, type: string): Promise<any | undefined> {
        return new Promise((resolve, reject) => {
            const request = indexedDB.open(this.dbName);
            request.onerror = (event) => {
                reject(request.error);
            };
            request.onsuccess = (event) => {
                const db = request.result;
                const transaction = db.transaction(type, 'readonly');
                const objectStore = transaction.objectStore(type);
                const getRequest = objectStore.get(key);
                getRequest.onerror = (event) => {
                    reject(getRequest.error);
                };
                getRequest.onsuccess = (event) => {
                    resolve(getRequest.result);
                };
            };
        });
    }

    private async putInDB(key: string, type: string, value: any): Promise<void> {
        return new Promise((resolve, reject) => {
            const request = indexedDB.open(this.dbName);
            request.onerror = (event) => {
                reject(request.error);
            };
            request.onsuccess = (event) => {
                const db = request.result;
                const transaction = db.transaction(type, 'readwrite');
                const objectStore = transaction.objectStore(type);
                const putRequest = objectStore.put(value, key);
                putRequest.onerror = (event) => {
                    reject(putRequest.error);
                };
                putRequest.onsuccess = (event) => {
                    resolve();
                };
            };
        });
    }
}

const cache = new CacheUtil(5000, 'tolink', 3);

export enum CacheTypes {
    INCOME_FANS = 'income-fans',
    TRANSLATE = 'translate',
    CONVERSATIONS = 'conversations',
    CONFIG = 'config',
    MSG_LOG = 'msg_log',
}

export function getCache(key: string, type: string): Promise<any> {
    return cache.get(key, type);
}

export function putCache(key: string, type: string, value: any): Promise<any> {
    return cache.put(key, type, value);
}
