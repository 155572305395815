import getPeerActiveUsernames from "../../lib/appManagers/utils/peers/getPeerActiveUsernames";
import rootScope from "../../lib/rootScope";
export function getRamdomNum(start, end) {
  return Math.floor(Math.random() * (end - start)) + start;
}

export async function getUserNameByPeerId(peerId) {
  const user = await rootScope.managers.appUsersManager.getUser(
    peerId.toUserId()
  );
  const usernames = getPeerActiveUsernames(user);

  return usernames;
}

export async function getFullNameByPeerId(peerId) {
  let user = await rootScope.managers.appUsersManager.getUser(
    peerId.toUserId()
  );

  const fullName =
    user.first_name + (user.last_name ? " " + user.last_name : "");

  return fullName;
}

export function getSessionStorage(key) {
  return JSON.parse(sessionStorage.getItem(key));
}

export function setSessionStorage(key, value) {
  value = JSON.stringify(value);
  sessionStorage.setItem(key, value);
}
