export default function readOnlineFile(url) {
  // let url = "https://cdn.yay.chat/11111.png";
  console.log("**readOnlineFile :>> ");
  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    xhr.open("GET", url);
    xhr.responseType = "blob";
    xhr.onload = () => {
      if (xhr.status === 200) {
        resolve(xhr.response);
      } else {
        reject(xhr.statusText);
      }
    };
    xhr.onerror = () => reject(xhr.statusText);
    xhr.send();
  });
}

export function getDuration(blob) {
  return new Promise((resolve, reject) => {
    const audio = new Audio();
    audio.onloadedmetadata = () => {
      resolve(audio.duration);
    };
    audio.onerror = (e) => {
      reject(e);
    };
    audio.src = URL.createObjectURL(blob);
  });
}

export function readOnlineVoice(url) {
  console.log("**readOnlineFile :>> ");
  // let url = "https://cdn.yay.chat/Yay_Sweetie.ogg";
  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    xhr.open("GET", url);
    xhr.responseType = "blob";
    xhr.onload = () => {
      if (xhr.status === 200) {
        xhr.response
          .arrayBuffer()
          .then((arrayBuffer) => {
            const uint8Array = new Uint8Array(arrayBuffer, 0, 63);
            resolve(uint8Array);
          })
          .catch((err) => {
            reject(err);
          });
      } else {
        reject(xhr.statusText);
      }
    };
    xhr.onerror = () => reject(xhr.statusText);
    xhr.send();
  });
}

export function blobToUint8Array(blob, callback) {
  const reader = new FileReader();
  reader.onload = function () {
    const arrayBuffer = reader.result;
    const uint8Array = new Uint8Array(arrayBuffer);
    callback(uint8Array);
  };
  reader.readAsArrayBuffer(blob);
}

function blobToArrayBuffer(blob) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onloadend = () => resolve(reader.result);
    reader.onerror = reject;
    reader.readAsArrayBuffer(blob);
  });
}

async function getAudioBuffer(blob) {
  const arrayBuffer = await blobToArrayBuffer(blob);
  const audioContext = new (window.AudioContext || window.webkitAudioContext)();
  return audioContext.decodeAudioData(arrayBuffer);
}

function extractWaveformData(audioBuffer) {
  const channelData = audioBuffer.getChannelData(0); // 获取第一个通道的数据
  // 这里的 channelData 是一个 Float32Array，包含波形的样本值
  return channelData;
}

export async function processAudioBlob(blob) {
  try {
    const audioBuffer = await getAudioBuffer(blob);
    const waveformData = extractWaveformData(audioBuffer);

    console.log(`** waveformData處理結果：`, waveformData);
    return waveformData;
    // 处理 waveformData
  } catch (error) {
    console.error("处理音频 Blob 时出错:", error);
  }
}
