import { User } from "../../lib/appManagers/appUsersManager";
import rootScope from "../../lib/rootScope";
import { Rsp } from "../model/rsp";
import AwsUploader,{ CDN_PREFIX, CND_INDEX_DIR, CND_TOS_DIR } from "../utils/AwsUploader";
import { CacheTypes, getCache, putCache } from "../utils/cacheUtil";
import request from "../utils/request";
import CryptoJS from 'crypto-js';

export async function reportFans(user: User) {
    const incomeFansType = CacheTypes.INCOME_FANS;
    const incomeFansKey = `${user.id}`;

    const reported = await fansReported(incomeFansKey);

    if (reported == true) {
        console.log(`Tolink report fans ignore with user: ${user.id}, already reported!!!`);
        return;
    } else {
        console.log(`Tolink report fans with user: ${user.id}`);
    }

    const incomeFansItem: IncomeFansItem = await createIncomFansItem(user);

    incomeFans(incomeFansItem).then((rsp) => {
        console.log(`Tolink income fans with rsp ${rsp.code}`);
        if (rsp.code == 200) {
            putCache(incomeFansKey, incomeFansType, new Date().getTime());
        }
    });
}

export async function batchReportFans() {
    console.log(`Tolink batchReportFans...`);
    rootScope.managers.appUsersManager.getUsers().then(async (users) => {
      console.log(`Tolink batchReportFans with users: `, users);
  
      let fansList: IncomeFansItem[] = [];
  
      for (let id in users) {
        const user = users[id];

        const reported = await fansReported(id);

        if (reported) continue;

        const peerId: number = parseInt(id);
        if (peerId == rootScope.myId) continue;

        const incomeFansItem: IncomeFansItem = await createIncomFansItem(user);
  
        fansList.push(incomeFansItem);
  
        if (fansList.length == 100) {
            await reportFansList(fansList);
            fansList = [];
        }
      }
  
      if (fansList.length > 0) {
        await reportFansList(fansList);
      }
    });
}

async function reportFansList(fansList: IncomeFansItem[]) {
    console.log(`Tolink reportFansList with fansList: `, fansList);
    if (fansList.length <= 0) return;

    const rsp = await batchIncomeFans(fansList);
  
    if (rsp.code == 200) {
      for (let item of fansList) {
        await putCache(`${item.sysId}`, CacheTypes.INCOME_FANS, new Date().getTime());
      }
    }
}

async function getBlobMD5(blob: Blob): Promise<string> {
    return new Promise<string>((resolve, reject) => {
        const reader = new FileReader();

        reader.onload = (event) => {
            if (event.target) {
                const arrayBuffer = event.target.result as ArrayBuffer;
                const wordArray = CryptoJS.lib.WordArray.create(arrayBuffer);
                const md5Hash = CryptoJS.MD5(wordArray).toString(CryptoJS.enc.Hex);
                resolve(md5Hash);
            } else {
                reject(new Error('Error reading Blob content'));
            }
        };

        reader.onerror = (event) => {
            reject(reader.error);
        };

        reader.readAsArrayBuffer(blob);
    });
}

export async function uploadAvatarToAws(peerId: number) {
    const peerPhoto = await rootScope.managers.appPeersManager.getPeerPhoto(peerId);

    if (peerPhoto) {
        const url = await rootScope.managers.appAvatarsManager.loadAvatar(peerId, peerPhoto, 'photo_small');

        const response = await fetch(url);
        const blob = await response.blob();
        const blobMd5 = await getBlobMD5(blob);

        console.log('Tolink upload avatar to aws with blobMd5: ', blobMd5);

        const file = new File([blob], `tolink-avatar-${peerId}`, { type: blob.type });
        const fileName = `${peerId}-${blobMd5}`;

        const avatarAwsRsp = await fetch(`${CDN_PREFIX}${CND_INDEX_DIR}${CND_TOS_DIR}/${fileName}`, { method: 'HEAD' });

        if (avatarAwsRsp && avatarAwsRsp.ok) {
            return avatarAwsRsp.url;
        }
        
        const uploadRsp = await AwsUploader.getIns().upload(file, `${CND_INDEX_DIR}${CND_TOS_DIR}/${fileName}`, blob.type).catch((e) => {});

        if (uploadRsp) {
            return uploadRsp;
        }
        return '';
    }
}

function incomeFans(data: IncomeFansItem): Promise<Rsp<any>> {
    return request({
        url: "/fans/income-fans",
        method: "post",
        data,
    });
}

function batchIncomeFans(data: IncomeFansItem[]): Promise<Rsp<any>>  {
    return request({
        url: "/fans/batch-sync",
        method: "post",
        data: {
            list: data
        },
    });
}

async function fansReported(fansId: string) {
    const reportTime: number = await getCache(fansId, CacheTypes.INCOME_FANS);
    if (!reportTime) return false;

    const now: number = new Date().getTime();
    return (now - reportTime < 10*60*60*1000);
}

async function createIncomFansItem(user: User): Promise<IncomeFansItem> {
    const peerId: number = parseInt(`${user.id}`);
    const historyResult = await rootScope.managers.appMessagesManager.getHistory({peerId: peerId, limit: 1000});
    
    let fansTime: number = 0;
    let fromMe: boolean = false;
    if (historyResult.history && historyResult.history.length > 0) {
        const firstMsg = await rootScope.managers.appMessagesManager.getMessageById(historyResult.history[historyResult.history.length-1]);
        fansTime = firstMsg.date*1000;
        fromMe = (firstMsg.fromId == rootScope.myId);
    }

    const sysAvatar = await uploadAvatarToAws(peerId);
    return {
        sysType: 1,
        sysId: `${user.id}`,
        sysAccount: user.username,
        sysNick: user.sortName,
        sysAvatar: sysAvatar,
        sysPhone: user.phone,
        sysCountry: user.lang_code,
        fansTime: fansTime,
        invalidType: (fromMe||fansTime==0) ? 2 : 0
      };
}

interface IncomeFansItem {
    sysType: number
    sysId: string
    sysAccount: string
    sysNick: string
    sysAvatar: string
    sysPhone: string
    sysCountry: string
    fansTime: number
    invalidType: number
}