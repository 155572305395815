export default class SecretStore{

    public static KEY_AWS = 'ss.key.aws'


    private static ins: SecretStore = null

    public static getIns(): SecretStore{
        if(SecretStore.ins == null ){
            SecretStore.ins = new SecretStore()
        }
        return SecretStore.ins;
    }

    private constructor(){
        this.storeMap = new Map<String,any>()
    }

    private storeMap: Map<String,any>

    set(key: string, value: any){
        if(!key || key == null || key.length <= 0) return
        if(!value || value == null){
            this.storeMap.delete(key)
        }else{
            this.storeMap.set(key,value)
        }
    }

    get<T>(key: string): T{
        return this.storeMap.get(key)
    }
}

export interface AwsConfig{
    key: string,
    secret: string,
    region: string
}