import axios, { AxiosHeaders } from "axios";
import { checkBind } from "../api/authApi";
import rootScope from "../../lib/rootScope";

// 创建axios的对象
const instance = axios.create({
  // baseURL: import.meta.env.VITE_BASE_URL, //配置固定域名
  // baseURL: "http://192.168.50.21:9991/v1", //配置固定域名
  // baseURL: "http://localhost:9991/v1", //配置固定域名
  baseURL: import.meta.env.VITE_BASE_URL, //配置固定域名
  timeout: 30000,
});

// 请求拦截
// 所有的网络请求都会走这个方法,可以在请求添加自定义内容
instance.interceptors.request.use(
  function (config) {
    // config.headers.token = "123456"; // 请求头添加token值
    // config.headers.info = 'lxy'  //请求头添加info值
    config.headers["Content-Type"] = "application/json;charset=utf-8;";
    // config.headers["df_platform"] = "web";
    config.headers["Authorization"] = sessionStorage.getItem("tolink_token");
    config.headers["auth-platform"] = "app";
    // config.withCredentials = true; // 添加此行以携带跨域请求时的认证信息
    const commHeaders = genCommonHeaders()
    if (commHeaders != null) {
      config.headers.set(commHeaders, false)
    }
    return config;
  },
  function (err) {
    return Promise.request(err);
  }
);

var commonHeaders = null

function genCommonHeaders() {
  if (commonHeaders == null) {
    try {
      const headers = JSON.parse(sessionStorage.getItem('headers'))
      if (headers) {
        commonHeaders = new AxiosHeaders({
          'X-Channel': headers.channel,
          'X-Os': headers.os,
          'X-Device-Model': headers.deviceModel,
          'X-Mac': headers.mac,
          'X-App-Version': headers.appVersion,
          'X-Device-Id': headers.deviceId,
          'X-Cpu-Type': headers.cpu,
          'X-Terminal': headers.terminal
        })
      }
    } catch (error) {
      console.log(error)
    }
  }
  return commonHeaders;
}

// 响应拦截
// 此处可以根据服务器返回的状态码做相应的数据
instance.interceptors.response.use(
  async function (response) {
    const res = response;
    console.log(`Tolink receive response: ${JSON.stringify(res)}`);
    if (res.status == 200) {
      if (res.data.code == 402) {
        console.log(`Tolink token expired, refresh from server`);

        const tgId = rootScope.myId;
        const bindResult = await checkBind(tgId);
        if (!bindResult) {
          console.log(
            `Tolink token expired and checkBind false, show login dialog`
          );

          let joinGroupDom = document.getElementById("isJoinGroup");
          joinGroupDom.style.display = "block";
        }
      }
      return Promise.resolve(res.data);
    } else {
      return Promise.reject("error");
    }
  },
  function (err) {
    return Promise.reject(err);
  }
);

// 封装get和post请求
export function get(url, params) {
  return instance.get(url, { params });
}

export function post(url, data) {
  return instance.post(url, data);
}

export default instance;
