import { User } from "../../lib/appManagers/appUsersManager";
import rootScope from "../../lib/rootScope";
import { getUserInfo, setUserInfo2Pc } from "../utils/pcApi";
import request from "../utils/request";
import { uploadAvatarToAws } from "./fansApi";

const sysType = 1;

// check绑定关系
export async function checkBind(sysId: number) {
    const rsp: any = await request({
        url: "/auth/check-group-binding",
        method: "post",
        data: {
            'sysType': sysType,
            'sysId': sysId
        }
    });

    console.log(`Tolink checkBind from server with rsp: ${JSON.stringify(rsp)}`);

    let result = false;
    if (rsp.code == 200 && rsp.data.groupId > 0) {
        console.log(`Tolink checkBind ok, save token and sync user info`);

        sessionStorage.setItem("tolink_groupId", rsp.data.groupId);
        sessionStorage.setItem("tolink_groupCode", rsp.data.groupCode);
        sessionStorage.setItem("tolink_token", rsp.data.accessToken);
        sessionStorage.setItem("tolink_groupMemberId", rsp.data.groupMemberId);
        sessionStorage.setItem("tolink_groupMemberMark", rsp.data.mark);
        sessionStorage.setItem("tolink_groupName", rsp.data.groupName);
        sessionStorage.setItem("tolink_targetApp", rsp.data.targetApp);
        sessionStorage.setItem("tolink_inviteCode", rsp.data.inviteCode);
        window.setHeartCheck()
        getUserInfo();

        result = true;
    } else {
        sessionStorage.removeItem("tolink_groupId");
        sessionStorage.removeItem("tolink_groupCode");
        sessionStorage.removeItem("tolink_token");
        sessionStorage.removeItem("tolink_groupMemberId");
        sessionStorage.removeItem("tolink_groupMemberMark");
        sessionStorage.removeItem("tolink_groupName");
        sessionStorage.removeItem("tolink_targetApp");
        sessionStorage.removeItem("tolink_inviteCode");
        setUserInfo2Pc();
    }

    return result;
}

// 绑定工作组
export async function bindGroup(user: User, groupCode: string, inviteCode: string, targetApp: string) {
    const userParams = await buildParams(user);
    return request({
        url: "/auth/bind-group",
        method: "post",
        data: {
            'sysType': 1,
            'groupCode': groupCode,
            inviteCode,
            targetApp,
            ...userParams
        }
    });
}

// 修改工作号信息
export async function modifyUserInfo() {
    const tgId = rootScope.myId
    const tgUser = await rootScope.managers.appUsersManager.getUser(tgId)

    const userParams = await buildParams(tgUser);
    console.log('Tolink modify user info with params:', userParams);
    return request({
        url: "/group-member/modify",
        method: "post",
        data: {
            ...userParams
        }
    });
}

// 转换user
async function buildParams(user: User) {
    const sysAvatar = await uploadAvatarToAws(Number(user.id));
    
    return {
        'sysId': user.id,
        'sysAccount': user.username,
        'sysNick': user.sortName,
        'sysPhone': user.phone,
        'sysCountry': user.lang_code,
        'sysAvatar': sysAvatar
    }
}
